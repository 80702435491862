<template>
  <main>
    <PageTitleContainer>
      <template #default>
        <PageTitle>Paesi</PageTitle>
      </template>
      <template #actions>
        <router-link class="btn btn-outline-primary" :to="{ name: 'settings.countries.add' }">Aggiungi</router-link>
      </template>
    </PageTitleContainer>

    <FetchList :loading="loading">
      <div class="container">
        <AwlTable v-if="countries && countries.length > 0" :heading="tableHeadings" :rows="countries" striped>
          <template #column-key-code="{ value }">
            <span class="text-uppercase">{{ value }}</span>
          </template>

          <template #column-key-enabled="{ value }">
            <span>{{ value ? 'Sì' : 'No' }}</span>
          </template>

          <template #column-key-vatRates="{ value }">
            <span v-if="!value || value.length === 0">Nessuna aliquota</span>
            <div v-else-if="value.length === 1 && !value[0].min && !value[0].max" class="text-end">
              {{ value[0].vatRate }}%
            </div>
            <template v-else>
              <div v-for="(vatRate, pos) in value" :key="'vatRate-' + pos" class="text-end">
                {{ vatRate.vatRate }}% ({{ vatRate.min }}-{{ vatRate.max }})
              </div>
            </template>
          </template>

          <template #column-key-exciseDuties="{ value, row }">
            <span v-if="!value || value.length === 0">Nessuna accisa</span>
            <div v-else-if="value.length === 1 && !value[0].min && !value[0].max" class="text-end">
              {{ value[0].exciseDuty / 100 }} {{ row.currencyCode }}/HL
            </div>
            <template v-else>
              <div v-for="(exciseDuty, pos) in value" :key="'exciseDuty-' + pos" class="text-end">
                {{ exciseDuty.exciseDuty / 100 }} {{ row.currencyCode }}/HL ({{ exciseDuty.min }}-{{ exciseDuty.max }})
              </div>
            </template>
          </template>

          <template #column-key-sparklingExciseDuties="{ value, row }">
            <span v-if="!value || value.length === 0">Nessuna accisa</span>
            <div v-else-if="value.length === 1 && !value[0].min && !value[0].max" class="text-end">
              {{ value[0].exciseDuty / 100 }} {{ row.currencyCode }}/HL
            </div>
            <template v-else>
              <div v-for="(exciseDuty, pos) in value" :key="'sparklingExciseDuty-' + pos" class="text-end">
                {{ exciseDuty.exciseDuty / 100 }} {{ row.currencyCode }}/HL ({{ exciseDuty.min }}-{{ exciseDuty.max }})
              </div>
            </template>
          </template>

          <template #actions-content="{ row }">
            <ActionEdit :to="{ name: 'settings.countries.edit', params: { code: row.code } }" />
            <ActionShow class="ms-2" :to="{ name: 'settings.countries.show', params: { code: row.code } }" />
            <ActionGeneric class="ms-2" label="Listino" iconClass="fas fa-coins" :to="{ name: 'settings.countries.prices', params: { code: row.code } }" />
          </template>
        </AwlTable>
      </div>
    </FetchList>
  </main>
</template>

<script>

import $api from '@/libs/OAuth2/services/api.js';
import FetchList from '@/views/components/Layout/FetchList.vue';
import AwlTable from '@/libs/Table/components/AwlTable.vue';
import ActionShow from '@/libs/Table/components/Actions/ActionShow.vue';
import ActionGeneric from '@/libs/Table/components/Actions/ActionGeneric.vue';
import ActionEdit from '@/views/components/Actions/ActionEdit.vue';
import PageTitleContainer from '@/views/components/Private/PageTitleContainer.vue';
import PageTitle from '@/views/components/Private/PageTitle.vue';

function beforeRouteEnterOrUpdate (to, from, next) {
  $api.listCountries()
    .then(res => next(vm => {
      vm.countries = res?.data?.countries || [];
    }))
    .catch(() => next(from))
  ;
}

export default {
  components: { FetchList, AwlTable, ActionShow, ActionGeneric, ActionEdit, PageTitleContainer, PageTitle },
  data () {
    return {
      loading: false,
      countries: null,
    };
  },
  computed: {
    tableHeadings () {
      return [
        { key: 'code', label: 'Codice' },
        { key: 'name', label: 'Nome' },
        { key: 'currencyCode', label: 'Valuta' },
        { key: 'enabled', label: 'Attivo' },
        { key: 'vatRates', label: 'Aliquote Iva' },
        { key: 'exciseDuties', label: 'Accise sul vino' },
        { key: 'sparklingExciseDuties', label: 'Accise sugli spumanti' },
      ];
    },
  },
  beforeRouteEnter: beforeRouteEnterOrUpdate,
  beforeRouteUpdate: beforeRouteEnterOrUpdate,
};

</script>
